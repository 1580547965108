import React, { useEffect, useState, useRef } from "react";
import { CircularProgress } from "@mui/material";
import { Radio, Input } from "antd";
import { useDispatch } from "react-redux";
import { notification } from "antd";

import { updateReportOrder } from '../../../../actions/SAJobMgmtAction';
import { Strings } from "../../../../dataProvider/localize";
import styles from "../../v2Styles.module.css";

export default function ChangeRecordOrder({ onClose, selectedRecord, jobId }) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState();
  const [position, setPosition] = useState("");
  const ref = useRef();

  useEffect(() => {
    if (option && option !== 3) {
      setPosition("");
    } else {
      if (option && option === 3) {
        ref.current.focus();
      }
    }
  }, [option]);

  const setMethodOrder = () => {
    setLoading(true);
    let formData = {
      job_id: jobId,
      id: selectedRecord.id,
      quote_task_id: selectedRecord.quote_task_id,
      original_order: selectedRecord.report_order
    };
    if (option === 1) {
      formData.move_to = "TOP";
    }
    else if (option === 2) {
      formData.move_to = "BOTTOM";
    }
    else if (option === 3) {
      formData.target_order = parseInt(position);
    }

    dispatch(updateReportOrder(formData))
      .then((message) => {
        notification.success({
          message: Strings.success_title,
          description: message,
          className: "ant-success"
        })
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
          notification.error({
            message: Strings.error_title,
            description: err ? err : Strings.generic_error,
            className: "ant-error"
          })
        }
      })
  }

  return (
    <div className="swms-masters-modal-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={styles.ModalHeading}>
              Change Record Order
            </div>
            <div className="">
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div>
              <Radio.Group onChange={(e) => setOption(e.target.value)} value={option}>
                <Radio value={1} className="cs-radio-v2 d-block mb-2">Move to Top</Radio>
                <Radio value={2} className="cs-radio-v2 d-block">Move to Bottom</Radio>
                <Radio value={3} className="cs-radio-v2 d-block">
                  Specify Position
                  <Input
                    ref={ref}
                    disabled={option !== 3}
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    type="number"
                    min={1}
                    className={styles.TextField}
                    style={{ width: 120, marginLeft: 10 }}
                  />
                </Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right pt-3">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "end", columnGap: "10px" }}>
              {loading ? (
                <CircularProgress size={20} />) : ""}
              <button type="button" className={`${styles.OutlinedButton}`} onClick={onClose} style={{ padding: "0px 5px" }}>
                Cancel
              </button>
              <button
                disabled={loading}
                className={styles.PrimaryButton}
                onClick={() => { setMethodOrder() }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}