import React, { useState, useEffect, useMemo } from "react";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity"
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { Upload, notification, Menu, Dropdown } from "antd";
import DescriptionIcon from '@mui/icons-material/Description';
import { LinearProgress, IconButton, Typography } from "@mui/material";
import { getStorage, downloadDocument } from "../../../../utils/common";
import { JWT_ID_TOKEN, ADMIN_DETAILS } from "../../../../dataProvider/constant";

import styles from "../../Job/JobStyle.module.css";

export default function ReportDocumentUploadField({ input, disabled, mimeType, onDelete, previewFile, s3UploadPath, meta: { touched, error } }) {

  const [isUploading, setIsUploading] = useState(false);

  const controller = new AbortController();
  const supportedTypes = [
    "image/png",
    "image/jpeg",
    "image/webp",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf"
  ];
  const imageTypes = ["png", "jpg", "jpeg", "webp"];
  const allTypes = ["png", "jpg", "jpeg", "webp", "pdf", "doc", "docx", "xls", "xlsx"]

  const isPreviewable = useMemo(() => {
    if (input.value && input.value.file_name && typeof previewFile !== "undefined") {
      let nameSplit = input.value.file_name.split(".");
      let type = nameSplit[nameSplit.length - 1];
      if (imageTypes.includes(type)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [input.value, imageTypes]);

  const uploadPreviewPath = useMemo(() => {
    let pathValue = ""
    if (input.value && input.value.originFileObj) {
      let nameSplit = input.value.originFileObj.name.split(".");
      let type = nameSplit[nameSplit.length - 1];
      if (imageTypes.includes(type)) {
        let file = input.value;
        pathValue = URL.createObjectURL(file.originFileObj);
      }
    } else {
      if (input.value && input.value.file_name && input.value.file_base_path) {
        let nameSplit = input.value.file_name.split(".");
        let type = nameSplit[nameSplit.length - 1];
        if (imageTypes.includes(type)) {
          pathValue = `${input.value.file_base_path}${input.value.file_name}`
        }
      }
    }
    return pathValue;
  }, [input.value, imageTypes])

  useEffect(() => {
    if (input.value && input.value.originFileObj) {
      let file = input.value;
      if (!file.isUploaded) {
        uploadFile();
      }
    }
  }, [input.value]);

  const uploadFile = () => {
    if (!s3UploadPath) {
      input.onChange(null);
      notification.error({
        message: "Unable to Process file Upload",
        className: "ant-error"
      });
      console.error("Failed to upload file - Upload Path not provided");
    } else {
      setIsUploading(true);
      let region = process.env.REACT_APP_REGION;
      let logins = {};
      logins[process.env.REACT_APP_COGNITO_USER_POOL_ID] = getStorage(JWT_ID_TOKEN);
      let client = new S3Client({
        region,
        credentials: fromCognitoIdentityPool({
          identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
          client: new CognitoIdentityClient({ region }),
          logins,
          clientConfig: { region },
        })
      });

      try {
        let slug = JSON.parse(getStorage(ADMIN_DETAILS)).organisation.slug;
        let path = `${s3UploadPath}/${new Date().getTime()}-${input.value.originFileObj.name}`;
        Promise.resolve(client.send(
          new PutObjectCommand({ Bucket: process.env.REACT_APP_TEMP_BUCKET, Key: slug + "/" + path, Body: input.value.originFileObj, ContentType: input.value.originFileObj.type }),
          { abortSignal: controller.signal }
        ))
          .then(() => { input.onChange({ ...input.value, visible_name: input.value.originFileObj.name, file_name: path, isUploaded: true, isFailed: false }) })
          .catch(err => {
            notification.error({
              message: "Failed to upload File.",
              className: "ant-error"
            });
            input.onChange({ ...input.value, isUploaded: true, isFailed: true })
            console.log(err);
          })
          .finally(() => setIsUploading(false))
      }
      catch (e) {
        notification.error({
          message: "Failed to upload File.",
          className: "ant-error"
        });
        console.log(e);
        input.onChange({ ...input.value, isUploaded: false, isFailed: true });
      }
    }
  }

  const downloadAttachment = () => {
    try {
      let organisation = JSON.parse(getStorage(ADMIN_DETAILS)).organisation;
      let domain = new URL(organisation.logo_path).origin;
      let slug = organisation.slug;
      let fullPath = `${domain}/${slug}/${input.value.file_name}`;
      downloadDocument(fullPath)
    } catch (e) {
      notification.error({
        message: "Failed to Download File.",
        className: "ant-error"
      });
      console.log(e);
    }
  }

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [])

  const preview = () => {
    try {
      let organisation = JSON.parse(getStorage(ADMIN_DETAILS)).organisation;
      let domain = new URL(organisation.logo_path).origin;
      let slug = organisation.slug;
      let fullPath = `${domain}/${slug}/${input.value.file_name}`;
      previewFile(fullPath)
    } catch (e) {
      notification.error({
        message: "Failed to Preview File.",
        className: "ant-error"
      });
      console.log(e);
    }
  }

  const handleOption = ({ key }) => {
    if (key === "3") {
      if (typeof onDelete !== "undefined") {
        onDelete()
      } else {
        input.onChange(null);
      }
    } if (key === "2") {
      downloadAttachment();
    }
    if (key === "1") {
      if (typeof previewFile !== "undefined" && isPreviewable) {
        preview()
      }
    }
  }

  const optionMenu = () => (
    <Menu onClick={handleOption}>
      <Menu.Item key="1" disabled={isUploading || !isPreviewable}>
        Preview
      </Menu.Item>
      <Menu.Item key="2" disabled={isUploading || input.value && input.value.isFailed}>
        Download
      </Menu.Item>
      <Menu.Item key="3">
        Delete
      </Menu.Item>
    </Menu>
  )

  const getNameFromFileName = (path) => {
    let parts = path.split("/");
    return parts[parts.length - 1]
  }

  return (
    <div>
      <div onClick={(e) => e.stopPropagation()} className="d-flex" style={{ gap: "5px" }}>
        <div>
          <Upload
            showUploadList={false}
            beforeUpload={() => { return false; }}
            accept={allTypes.map(x => "." + x).join(",")}
            onChange={({ file }) => {
              if (supportedTypes.includes(file.type)) {
                input.onChange({ ...input.value, originFileObj: file, isUploaded: false })
              } else {
                notification.error({
                  message: "Unsupported File Type",
                  description: `Only Images and Document files (${allTypes.map(x => "." + x).join(", ")}) are accepted.`,
                  className: 'ant-error'
                })
              }
            }}>
            <div className="d-flex" style={{ gap: "5px" }}>
              <div>
                <button disabled={disabled || isUploading} type="button" className={styles.PrimaryButton}>Choose File</button>
              </div>
            </div>
          </Upload>
        </div>
        {input.value ? (
          <div className="" style={{ minWidth: "0px", flex: "1" }} onClick={e => e.stopPropagation()}>
            <div className={`${styles.UploadBox} pt-0`}>
              <div className={styles.UploadImageWrapper}>
                {uploadPreviewPath ? (
                  <div className="d-flex align-items-center h-100">
                    <img
                      src={uploadPreviewPath}
                      className={`${styles.UploadPhoto}`}
                      alt="preview" />
                  </div>
                ) : (
                  <DescriptionIcon sx={{ fontSize: "25px" }} />
                )}
              </div>
              <div className="px-1" style={{ minWidth: "0px" }}>
                <Typography fontSize={13} noWrap> {input.value.originFileObj && input.value.originFileObj.name ? input.value.originFileObj.name : input.value && input.value.visible_name ? input.value.visible_name : getNameFromFileName(input.value.file_name)}
                </Typography>
              </div>
              <div className={`${styles.AttachemntIcons}`}>
                <div>
                  <Dropdown overlayClassName="cs-v3-dropdown" overlay={optionMenu} disabled={disabled}>
                    <IconButton style={{ height: "auto" }} disabled={disabled}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="16px" width="16px" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                      </svg>
                    </IconButton>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="" style={{ height: " 4px" }}>
              {isUploading ? (
                <LinearProgress sx={{ height: "2px" }} />
              ) : ""}
            </div>
            {input.value.isFailed ? (
              <div className={styles.UploadErrorBox}>
                Failed to Upload.
              </div>) : ""}
          </div>
        ) : ""}
      </div>
      {touched && (error &&
        <span className="error-input">{error}</span>
      )}
    </div>

  )
}