import React, { useState, useEffect } from "react";
import styles from "../../Job/JobStyle.module.css";
import Dragger from "antd/lib/upload/Dragger";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { Field } from "redux-form";
import { notification } from "antd";
import ReportImageField from "./ReportImageField";

const ReportFilesContainer = ({ fields, taskFiles, s3UploadPath, loading, previewFile, maxSize = null, label = "" }) => {

  const supportedTypes = [
    "image/png",
    "image/jpeg",
    "image/webp",
  ];

  const [size, setSize] = useState(0);

  useEffect(() => {
    if (taskFiles && taskFiles.length > 0) {
      let totalSize = 0;
      taskFiles.forEach(x => {
        if (x.file_name && x.file_name.originFileObj && x.file_name.originFileObj.size) {
          totalSize += x.file_name.originFileObj.size;
        }
      })
      setSize(totalSize);
    } else {
      setSize(0)
    }
  }, [taskFiles])


  const onChange = ({ file, fileList }) => {
    if (supportedTypes.includes(file.type)) {
      let totalSize = 0;
      fileList && fileList.forEach(x => {
        if (x.file_name && x.file_name.originFileObj && x.file_name.originFileObj.size && x.file_name.originFileObj.uid !== file.uid) {
          totalSize += x.file_name.originFileObj.size;
        } else if (x.size && x.uid && x.uid !== file.uid) {
          totalSize += x.size;
        }
      })
      totalSize += file.size;
      if (maxSize) {
        if (totalSize < maxSize) {
          fields.push({ file_name: { originFileObj: file, isUploaded: false } })
        } else {
          notification.error({
            message: "Error!",
            description: "Total attachments size exceeds limit.",
            className: "ant-error",
            key: file.lastModified.toString()
          })
        }
      } else {
        fields.push({ file_name: { originFileObj: file, isUploaded: false } })
      }
    } else {
      notification.error({
        message: "Unsupported File Type",
        description: `Only Images (.jpg, .jpeg, .png, .webp) are accepted.`,
        className: 'ant-error'
      })
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-12 pb-2">
          <div className={styles.Label}>{label ? label : "Upload Images"}</div>
          <div>
            <Dragger
              multiple
              disabled={loading}
              className="job-swms-photo-uploader"
              showUploadList={false}
              fileList={taskFiles ? taskFiles.map(x => x.file_name && x.file_name.originFileObj ? x.file_name.originFileObj : x) : []}
              accept="jpg, .jpeg, .webp, .png"
              beforeUpload={() => { return false; }}
              onChange={onChange}
            >
              <div className="d-flex align-items-end justify-content-around">
                <div>Drag & Drop Files</div>
              </div>
            </Dragger>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              {maxSize ? (
                <div className="text-left">
                  {taskFiles && taskFiles.length > 0 ? (
                    <span>
                      {(size / (1024 * 1024)).toFixed(2)}MB/{(maxSize / 1048576).toFixed(2)}MB.
                    </span>)
                    : <span>0.0MB/{(maxSize / 1048576).toFixed(2)}MB.</span>}
                </div>) : ""}
            </div>
            <div className="text-right">
              {taskFiles && taskFiles.length > 0 ? <span>{taskFiles.length === 1 ? "1 File" : `${taskFiles.length} Files`} Selected.</span>
                : <span>No File Selected.</span>}
            </div>
          </div>
        </div>
        {fields.map((field, index) => (
          <div className="col-6 mb-1" key={index.toString()}>
            <Field
              name={`${field}.file_name`}
              disabled={loading}
              previewFile={previewFile}
              component={ReportImageField}
              onDelete={() => fields.remove(index)}
              s3UploadPath={s3UploadPath}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default connect((state, initialProps) => ({
  taskFiles: (formValueSelector(initialProps.formName))(state, initialProps.fieldName)
}))(ReportFilesContainer);