import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { useDispatch } from "react-redux";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState, convertToRaw } from 'draft-js';

import { customInput } from "../../../common/custom-input";
import EmailEditor from '../../../common/EmailEditor';
import ReportDocumentUploadField from "./ReportDocumentUploadField";

import styles from "../../v2Styles.module.css";
import { CircularProgress } from "@mui/material";
import { notification } from "antd";
import { Strings } from "../../../../dataProvider/localize";
import { addUpdateJobReportDocument } from "../../../../actions/SAJobMgmtAction";

const validate = values => {
  const errors = {}

  if (!values.document_name) {
    errors.document_name = 'Required'
  }
  if (!values.files || (values.files && !values.files.length)) {
    errors.files = "Required";
  }
  if (!values.comments) {
    errors.comments = 'Required'
  }
  return errors
}

const AddEditJobReportDocument = ({ mode, onClose, handleSubmit, jobId, initialValues, change }) => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  useEffect(() => {
    let blocksFromHtml = htmlToDraft(initialValues.comments ? initialValues.comments : "");
    if (blocksFromHtml) {
      let contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks);
      let editorStateVal = EditorState.createWithContent(contentState);
      setEditorState(editorStateVal);
    }
  }, []);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    change("comments", draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const onSubmit = (formValues) => {
    let formData = {};
    if (!formValues.selected_document) {
      notification.error({
        message: Strings.error_title,
        description: 'Please add at least one photo to continue.',
        className: "ant-error",
      })
    } else {
      if (formValues.selected_document && !formValues.selected_document.isUploaded) {
        notification.error({
          message: Strings.error_title,
          description: "One or more files are still uploading or they failed to upload.",
          className: "ant-error"
        })
      } else {
        setLoading(true);
        let allNames = formValues.selected_document.file_name.split("/")
        let values = {
          document_name: formValues.document_name,
          comments: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          ...(formValues.id ? { id: formValues.id } : {}),
          document_files: allNames.splice(1, allNames.length).join("/"),
          job_id: jobId
        }
        if (mode === "Edit") {
          formData = { ...values };
        } else {
          formData.job_cleaning_reports = [];
          formData.job_cleaning_reports_documents = [values];
          formData.job_id = jobId
        }

        dispatch(addUpdateJobReportDocument(formData, mode === "Edit" ? false : true)).then(res => {
          setLoading(false);
          notification.success({
            message: Strings.success_title,
            description: res,
            className: "ant-success",
          });
          onClose();
        }).catch(error => {
          if (error) {
            setLoading(false);
            notification.error({
              message: Strings.error_title,
              description: error ? error : Strings.generic_error,
              className: "ant-error",
            });
          }
        })
      }
    }
  }

  return (
    <div className="v2-modal-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={styles.ModalHeading}>{mode === "Edit" ? `Edit Document` : "Add New Document"}</div>
            <div className="">
              <hr />
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12 pt-2">
              <div>
                <div className={styles.Label}>Document Name</div>
                <Field
                  component={customInput}
                  name="document_name"
                  disabled={loading}
                  autoComplete="off"
                  autoFocus
                  className={styles.TextField}
                />
              </div>
            </div>
            <div className="col-12 pt-2">
              <div>
                <div className={styles.Label}>Document</div>
                <Field
                  name="selected_document"
                  disabled={loading}
                  component={ReportDocumentUploadField}
                  s3UploadPath={"job_report_documents"}
                />
              </div>
            </div>
            <div className="col-12 pt-2">
              <div className={styles.Label}>Comments</div>
              <div className="my-1 form-group sf-form rich-textbox">
                <div className="sf-rich-txtbox">
                  <fieldset>
                    <Field
                      name="comments"
                      type="text"
                      id="name"
                      editorState={editorState}
                      onEditorStateChange={onEditorStateChange}
                      component={EmailEditor} />
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-right py-3">
              <div style={{ display: "flex", alignItems: "center", justifyContent: "end", columnGap: "10px" }}>
                {loading ? (
                  <CircularProgress size={20} />) : ""}
                <button type="button" className={`${styles.OutlinedButton}`} onClick={onClose} style={{ padding: "0px 5px" }}>
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className={styles.PrimaryButton}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default reduxForm({
  form: "AddEditJobReportDocument",
  validate,
  destroyOnUnmount: true,
})(AddEditJobReportDocument);