import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { notification } from "antd";
import { useDispatch } from "react-redux";

import { deleteDocument } from "../../../../actions/SAJobMgmtAction";
import { Strings } from "../../../../dataProvider/localize";

import v2Styles from "../../v2Styles.module.css";

export default function DeleteJobReportDocument({ selectedDocument, onClose }) {

  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const onDelete = () => {
    setSubmitting(true);
    dispatch(deleteDocument(selectedDocument)).then(message => {
      setSubmitting(false);
      notification.success({
        message: Strings.success_title,
        description: message,
        className: "ant-success"
      })
      onClose();
    }).catch(err => {
      if (err) {
        setSubmitting(false);
        notification.error({
          message: Strings.error_title,
          description: err ? err : Strings.generic_error,
          className: "ant-error"
        })
      }
    })
  }

  return (
    <div className="expense-accounts-modal-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={v2Styles.ModalHeading}>{`Are you sure you want to delete Document - ${selectedDocument.document_name}?`}</div>
            <div className="">
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div style={{ color: "red" }}>This action is not reversible.</div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "end", columnGap: "10px" }}>
              {submitting ? (
                <CircularProgress size={20} />) : ""}
              <button type="button" className={`${v2Styles.OutlinedButton}`} onClick={onClose} style={{ padding: "0px 5px" }}>
                Cancel
              </button>
              <button
                type="button"
                onClick={() => onDelete()}
                disabled={submitting}
                className={v2Styles.PrimaryButton}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}