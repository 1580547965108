import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { Close } from "@mui/icons-material";

import styles from "../../v2/Job/JobStyle.module.css";

export default function VideoPlayer(props) {

  const [file, setFile] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setFile(props.file);
  }, [props.file])

  useEffect(() => {
    setVisible(true);
  }, [])


  return (
    <div>

      <Modal
        className="cs-modal-v2"
        centered
        visible={visible}
        width="80vw"
        maskClosable={true}
        footer={null}
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
        afterClose={() => props.onCancel()}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        closeIcon={<div className={styles.ModalCloseIcon}><Close /></div>}
      >
        <div style={{ height: "80vh" }}>
          {file ? (
            <div className="w-100 h-100">
              <video src={file} style={{ height: '100%', width: '100%' }} controls autoPlay />
            </div>
          ) : null}
        </div>
      </Modal>
    </div>
  )

}
