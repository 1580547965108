import React, { useMemo, useState } from "react";
import CallIcon from '@mui/icons-material/Call';
import LanguageIcon from '@mui/icons-material/Language';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

import styles from "../../Job/JobStyle.module.css";
import "./JobReportStyles.css"
import { IconButton } from "@mui/material";
import moment from "moment";

export default function PreviewJobReport({ job, tasks, onDownload, reports, documents, basePath, onEmail }) {

  const [report, setReport] = useState(reports && reports.length > 0 ? reports[0] : null);

  return (
    <div className={`${styles.PreviewContainer} job-swms-preview-container d-flex`} style={{ flexDirection: "column" }}>
      <div className="container-fluid pb-5" style={{ flex: "1 1 80vh", overflow: "hidden auto" }}>
        <div>
          <header className="container-fluid pt-3">
            <div className="row my-1 align-items-center">
              <div className="col-3">
                <div className={styles.PreviewLogoWrapper}>
                  <img className={styles.JobPreviewLogo} src={job && job.org_logo ? job.org_logo : ''} alt="" />
                </div>
              </div>
              <div className="col-3">
                <div>
                  <span>{job && job.phone_number ? (
                    <span className="quote-icons-alignment"><CallIcon className="quote-preview-icons" /> {job.phone_number}</span>) : ""}
                  </span>
                </div>
                <div>
                  <span >{job && job.website ? (
                    <span className="quote-icons-alignment"><LanguageIcon className="quote-preview-icons" /> {job.website}</span>) : ""}
                  </span>
                </div>
                <div>
                  <span >{job && job.email_address ? (
                    <span className="quote-icons-alignment"><EmailIcon className="quote-preview-icons" /> {job.email_address}</span>) : ""}
                  </span>
                </div>
              </div>
              <div className="col-3">
                <div>
                  <span>{job && job.address ? (
                    <span className="quote-icons-alignment"><LocationOnIcon className="quote-preview-icons" /> {job.address}</span>) : ""}
                  </span>
                </div>
                <div>
                  <span >{job && job.abn_acn ? (
                    <span>
                      <span style={{ color: "#2aa3dd" }} className="bolded-text">ABN: </span>{job.abn_acn}</span>) : ""}
                  </span>
                </div>
              </div>
              <div className="col-2 offset-1">
                <div className="pdf-head-swms my-0" >JOB REPORT</div>
              </div>
            </div>
          </header>
          <div className="container-fluid pt-3">
            <div className="row">
              <div className="col-3 d-flex swms-rev-box px-3" style={{ gap: "5px", borderRadius: "10px 0 0 10px " }}>
                <div className="" style={{ flex: "0 0 auto" }}>Site: </div>
                <div className="text-right" style={{ flex: 1 }}>{job && job.site_name ? job.site_name : ""}</div>
              </div>
              <div className="col d-flex swms-rev-box px-3" style={{ gap: "5px" }}>
                <div className="" style={{ flex: "0 0 auto" }}>Site Address: </div>
                <div className="text-right" style={{ flex: 1, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{job && job.sites && job.sites.length && job.sites[0].site_address ? job.sites[0].site_address : ""}</div>
              </div>
              <div className="col-2 d-flex swms-rev-box px-3" style={{ gap: "5px" }}>
                <div className="" style={{ flex: "0 0 auto" }}>Quote Number:</div>
                <div className="text-right" style={{ flex: 1 }}>{job && job.quote_number ? job.quote_number : ""}</div>
              </div>
              <div className="col-2 d-flex swms-rev-box px-3" style={{ gap: "5px", borderRadius: "0px 10px 10px 0px " }}>
                <div className="" style={{ flex: "0 0 auto" }}>Purchase Order:</div>
                <div className="text-right" style={{ flex: 1 }}>{job && job.PO_number ? job.PO_number : ""}</div>
              </div>
            </div>
          </div>
          <div className="container-fluid pt-3">
            <div className="row align-items-stretch">
              <div className="col-6 swms-border py-2" style={{ borderRight: "none" }}>
                <div className="d-flex" style={{ gap: "5px" }}>
                  <div className="bolded-text pdf-heading-space">Prepared By:</div>
                  <div className="">{job && job.admin_name ? job.admin_name : ""}</div>
                </div>
                <div className="d-flex" style={{ gap: "5px" }}>
                  <div className="bolded-text pdf-heading-space">Company:</div>
                  <div>{job && job.org_name ? job.org_name : ""}</div>
                </div>
                <div className="d-flex" style={{ gap: "5px" }}>
                  <div className="bolded-text pdf-heading-space">Address:</div>
                  <div>{job && job.address ? job.address : ""}</div>
                </div>
                <div className="d-flex" style={{ gap: "5px" }}>
                  <div className="bolded-text pdf-heading-space">Mobile:</div>
                  <div>{job && job.admin_phone_number ? job.admin_phone_number : ""}</div>
                </div>
              </div>
              <div className="col-6 swms-border py-2">
                <div className="d-flex" style={{ gap: "5px" }}>
                  <div className="bolded-text pdf-heading-space">Attention:</div>
                  <div>{job && job.client_person_name ? job.client_person_name : ""}</div>
                </div>
                <div className="d-flex" style={{ gap: "5px" }}>
                  <div className="bolded-text pdf-heading-space">Company:</div>
                  <div>{job && job.client_name ? job.client_name : ""}</div>
                </div>
                <div className="d-flex" style={{ gap: "5px" }}>
                  <div className="bolded-text pdf-heading-space">Address:</div>
                  <div>{job && job.client_address ? job.client_address : ""}</div>
                </div>
                <div className="d-flex" style={{ gap: "5px" }}>
                  <div className="bolded-text pdf-heading-space">Mobile:</div>
                  <div>{job && job.client_person_phone ? job.client_person_phone : ""}</div>
                </div>
              </div>
            </div>
          </div>

          <SelectedView report={report} tasks={tasks} job={job} basePath={basePath} />
          <div className="swms-border mt-3">
            {reports.map(r => (
              <ReportListItem setReport={setReport} isSelected={report && report.id === r.id} report={r} key={r.id} basePath={basePath} />
            ))}
          </div>
          {documents.length > 0 ? (
            <div className="mt-3">
              <div className="swms-v4-table-heading">
                Additional Documents
              </div>
              <div className="swms-v4-border">
                <table className="swms-v4-table">
                  <thead>
                    <tr className="">
                      <th width="30%" className="swms-v4-normal-th px-3 font-weight-bold">Document Name</th>
                      <th width="50%" className="swms-v4-normal-th px-3 font-weight-bold">Comments</th>
                      <th width="20%" className="swms-v4-normal-th px-3 font-weight-bold">Document</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map(d => (
                      <tr key={d.id}>
                        <td className="px-3">{d.document_name}</td>
                        <td className="px-3" dangerouslySetInnerHTML={{ __html: d.comments }}></td>
                        <td className="px-3">
                          {d.shift_report_document_path ? <a target="_blank" href={d.shift_report_document_path} className={styles.SwmsPreviewLink} rel="noreferrer noopener">Attached Document</a> : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.JobReportPreviewFooter}>
        <button className={`${styles.SecondaryButton} mr-2`} onClick={() => onDownload()}>Download Report</button>
        <button className={`${styles.PrimaryButton} ml-auto`} onClick={() => onEmail()}>Email Job Report</button>
      </div>
    </div>
  )
}

function SelectedView({ report, tasks, job, basePath }) {

  const selectedTask = useMemo(() => {
    let task = tasks.find(x => report && x.id === report.quote_task_id);
    return task ? task : null;
  }, [report, tasks]);

  const featured = useMemo(() => {
    let video = report && report.files && report.files.find(x => x.type === "Video");
    if (!video) {
      video = report && report.files && report.files.length > 0 ? report.files[0] : null;
    }
    return video;
  })

  return report ? (
    <>
      <div className="container-fluid pt-3">
        <div className="row">
          <div className="col-3 d-flex swms-rev-box" style={{ gap: "5px" }}>
            <div className="pr-3">Job Number:</div>
            <div className="text-right">{job && job.job_number ? job.job_number : ""}</div>
          </div>
          <div className="col-4 d-flex swms-rev-box" style={{ gap: "5px" }}>
            <div className="pr-3">Task Name: {selectedTask ? selectedTask.task_name : ""}</div>
            <div className="text-right"></div>
          </div>
          <div className="col d-flex swms-rev-box" style={{ gap: "5px" }}>
            <div className="pr-3">Task Area: {selectedTask ? selectedTask.area_name : ""}</div>
            <div className="text-right"></div>
          </div>
        </div>
      </div>
      <div className="container-fluid pt-3">
        <div className="row align-items-stretch" style={{ gap: "5px" }}>
          <div className="col swms-rev-box p-3" style={{ borderRadius: "20px 0px 0px 20px" }}>
            <div>PHOTOS AND VIDEOS</div>
            <div className="d-flex align-items-center justify-content-center" style={{ height: "400px", width: "auto" }}>
              {featured ? featured.type === "Video" ? (
                <video controls style={{ width: "100%", height: "100%", objectFit: "scale-down", margin: "0px auto" }} src={basePath + featured.file_name} />
              ) : featured.type === "Photo" ? (
                <img className={`${styles.ReportImage} mx-auto`} style={{ width: "100%", height: "100%", objectFit: "scale-down" }} src={basePath + featured.file_name} alt="" />
              ) : null : null}
            </div>
            <div className="mt-2" style={{ flex: "3", gap: '10%', flexWrap: "wrap", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "10px" }}>
              {report && report.files && report.files.length ? report.files.map(x => (
                <div className={`${styles.ReportImageContainer} border-0 position-relative`} style={{ height: "120px" }} key={x.file_name}>
                  {x.type && x.type === "Photo" ? (
                    <img className={`${styles.ReportImage} mx-auto`} style={{ height: "auto" }} src={basePath + x.file_name} alt="" />
                  ) : x.type && x.type === "Video" ? (
                    <>
                      <div className="position-absolute d-flex align-items-center justify-content-center" style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)", background: "#fff", height: "24px", width: "24px", borderRadius: "50%" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" height="16" width="16" style={{ marginRight: "-2px" }}>
                          <path fill="#595959" d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
                        </svg>
                      </div>
                      <video style={{ width: "100%", height: "100%", objectFit: "scale-down", margin: "0px auto", padding: "5px" }} src={basePath + x.file_name} />
                    </>
                  ) : null}
                </div>
              )) : null}
            </div>
          </div>
          <div className="col-4 swms-rev-box p-3" style={{ borderRadius: "0px 20px 20px 0px" }}>
            <div>SHIFT DETAILS</div>
            <div className={styles.JobReportPreviewTextBox} style={{ minHeight: "150px" }}>
              Area - {report.area}
              {report.job_shift && report.job_shift.shift_date ? (<><br />{moment.utc(report.job_shift.shift_date).format("DD/MM/YYYY")} {moment.utc(report.job_shift.site_time).format("hh:mm A")} - {moment.utc(report.job_shift.finish_time).format("hh:mm A")}</>) : null}
            </div>
            <div className="mt-3">SHIFT NOTE</div>
            <div className={styles.JobReportPreviewTextBox} style={{ minHeight: "150px" }}>
              {report.note}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null
}

function ReportListItem({ report, isSelected, basePath, setReport }) {
  return (
    <div className={`container-fluid py-3 ${isSelected ? styles.JobReportPreviewSelected : ""}`}>
      <div className="row">
        <div className="col-3 py-2">
          <div className={styles.JobReportPreviewTextBox}>
            Area - {report.area}
          </div>
        </div>
        <div className="col-5">
          <div className="mt-2" style={{ flex: "3", gap: '10%', flexWrap: "wrap", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "10px" }}>
            {report.files && report.files.length ? report.files.map(x => (
              <div className={`${styles.ReportImageContainer} border-0 position-relative`} key={x.file_name} style={{ background: "#eef0f2", height: "90px" }}>
                {x.type && x.type === "Photo" ? (
                  <img className={`${styles.ReportImage} mx-auto`} style={{ height: "auto" }} src={basePath + x.file_name} alt="" />
                ) : x.type && x.type === "Video" ? (
                  <>
                    <div className="position-absolute d-flex align-items-center justify-content-center" style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)", background: "#fff", height: "24px", width: "24px", borderRadius: "50%" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" height="16" width="16" style={{ marginRight: "-2px" }}>
                        <path fill="#595959" d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
                      </svg>
                    </div>
                    <video style={{ width: "100%", height: "100%", objectFit: "scale-down", margin: "0px auto", padding: "5px" }} src={basePath + x.file_name} />
                  </>
                ) : null}
              </div>
            )) : null}
          </div>
        </div>
        <div className="col-3 py-2">
          <div className={styles.JobReportPreviewTextBox}>
            {report.note}
          </div>
        </div>
        <div className="col-1 py-2 d-flex align-items-center justify-content-center" style={{ maxHeight: "100px" }}>
          {isSelected ? (
            <svg xmlns="http://www.w3.org/2000/svg" height="30px" width="30px" viewBox="0 0 576 512" style={{ color: "#fff" }}>
              <path fill="currentColor" d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
            </svg>
          ) : (
            <IconButton onClick={() => setReport(report)} className="p-0" style={{ height: "min-content" }}>
              <svg xmlns="http://www.w3.org/2000/svg" height="30px" width="30px" viewBox="0 0 640 512" style={{ color: "#2aa3dd" }}>
                <path fill="currentColor" d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm9.4 130.3C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5l-41.9-33zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z" />
              </svg>
            </IconButton>
          )}
        </div>
      </div>
    </div>
  )
}