import React from "react";
import JobStyles from "../../Job/JobStyle.module.css";
import { Checkbox } from "antd";
import { IconButton, Tooltip } from "@mui/material";
import styles from "../../v2Styles.module.css";
import moment from "moment";

export default function JobReportRecord({ report, basePath, onVideoPlay, onEdit, onOrderUpdate, onDelete, checked, onChange }) {
  return (
    <div className={`col-12 pr-0`} style={{ border: "1px solid #f0f0f0", borderRadius: "5px", margin: "10px 0px" }}>
      <div className="mb-2 mr-3 d-flex pt-2" style={{ gap: "10px" }}>
        <Checkbox checked={checked} onChange={onChange} className="cs-select-v2" >Area - {report.area} <>
          {report.job_shift && report.job_shift.shift_date && report.job_shift.site_time && report.job_shift.finish_time ? <>
            , Shift: {moment.utc(report.job_shift.shift_date).format("DD/MM/YYYY")} {moment.utc(report.job_shift.site_time).format("hh:mm A")} - {moment.utc(report.job_shift.finish_time).format("hh:mm A")} </> : null}
        </></Checkbox>
        <div className="ml-auto d-flex" style={{ gap: "10px" }}>
          {report && report.report_order ? (
            <Tooltip title="Update Order">
              <IconButton className="p-0" style={{ height: "auto" }} onClick={() => onOrderUpdate(report)}>
                <svg xmlns="http://www.w3.org/2000/svg" className={styles.DragIcon} viewBox="0 0 576 512">
                  <path fill="currentColor" d="M137.4 502.6c12.5 12.5 32.8 12.5 45.3 0l96-96c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 402.7V288H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H448V109.3l41.4 41.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-96-96c-12.5-12.5-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L384 109.3V224H192 128 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96V402.7L86.6 361.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l96 96zM128 192h64V64c0-17.7-14.3-32-32-32s-32 14.3-32 32V192zM448 320H384V448c0 17.7 14.3 32 32 32s32-14.3 32-32V320z" />
                </svg>
              </IconButton>
            </Tooltip>
          ) : null}

          <Tooltip title="Edit Record">
            <span>
              <IconButton className={`${styles.IconButton} ${styles.SuccessColor}`} onClick={() => onEdit(report)} style={{ height: "auto", padding: "0px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={styles.EditIcon} style={{ height: "14px", width: "14px" }}>
                  <path fill="currentColor" d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                </svg>
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title="Delete Record">
            <span>
              <IconButton className={`${styles.IconButton} ${styles.DangerColor}`} style={{ height: "auto", padding: "0px" }} onClick={() => onDelete(report)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className={styles.DeleteIcon} style={{ height: "14px", width: "14px" }}>
                  <path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                </svg>
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
      <div className="d-flex pb-2" style={{ gap: "10px" }}>
        <div className="" style={{ flex: "3", gap: '10%', flexWrap: "wrap", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "10px" }}>
          {report.files && report.files.length ? report.files.filter(x => x.type && x.type === "Photo").map(x => (
            <div className={JobStyles.ReportImageContainer} key={x.file_name}>
              <img className={`${JobStyles.ReportImage} mx-auto`} src={basePath + x.file_name} alt="" />
            </div>
          )) : null}
          {/* fill empty photo space with placeholders */}
          {new Array(report.files && report.files.length ? (3 - (report.files.filter(x => x.type && x.type === "Photo").length % 3)) % 3 : 3).fill(null).map((_, i) => (
            <div className={JobStyles.ReportImageContainer} key={i} style={{ borderColor: "#f2f2f2", background: "#f1f1f1" }}>
            </div>
          ))}
        </div>
        <div style={{ flex: "1" }}>
          {report.files && report.files.filter(x => x.type && x.type === "Video").length ? (
            <div className={`${JobStyles.ReportImageContainer} position-relative`}>
              <div className="position-absolute d-flex align-items-center justify-content-center" style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)", background: "#fff", height: "24px", width: "24px", borderRadius: "50%" }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" height="16" width="16" style={{ marginRight: "-2px" }}>
                  <path fill="currentColor" d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
                </svg>
              </div>
              <video onClick={() => onVideoPlay(basePath + report.files.filter(x => x.type && x.type === "Video")[0].file_name)} className={`${JobStyles.ReportImage} mx-auto`} src={basePath + report.files.filter(x => x.type && x.type === "Video")[0].file_name} />
            </div>
          ) : (
            <div className={`${JobStyles.ReportImageContainer} position-relative`} style={{ borderColor: "#f2f2f2", background: "#f1f1f1" }}>
              <div className="position-absolute d-flex align-items-center justify-content-center text-center" style={{ color: "#b1b1b1", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "100%" }}>
                No Video Selected.
              </div>
            </div>
          )}
        </div>
        <div style={{ flex: "1" }}>
          <div className={JobStyles.CommonLabel}>Notes</div>
          <textarea disabled readOnly value={report.note} rows={3} className={JobStyles.ReportTextArea} />
        </div>
        <div style={{ flex: "1" }}>
          <div className={JobStyles.CommonLabel}>Feedback</div>
          <textarea disabled readOnly value={report.feedback} rows={3} className={JobStyles.ReportTextArea} />
        </div>
      </div>
    </div >
  )
}