import React, { useState } from "react";
import { Checkbox, Modal } from "antd";
import CloseIcon from '@mui/icons-material/Close';

import JobStyles from "../../Job/JobStyle.module.css";
import styles from "../../v2Styles.module.css";
import AddEditJobReportDocument from "./AddEditJobReportDocument";
import { IconButton } from "@mui/material";
import DeleteJobReportDocument from "./DeleteJobDocumentReport";

export default function JobReportDocumentsList({ jobId, documents, checked = [], refreshReports, onPreview, onDownload, basePath, selectAll, onChange }) {

  const [addEditModal, setAddEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const onAddNewRecord = () => {
    setSelectedDocument({});
    setAddEditModal(true);
  }

  const onDelete = (doc) => {
    setSelectedDocument({
      id: doc.id,
      job_id: jobId,
      document_name: doc.document_name
    });
    setDeleteModal(true);
  }

  const onEditDoc = (doc) => {
    setSelectedDocument({
      ...doc, selected_document: {
        file_name: `job_report_documents/` + doc.shift_report_document,
        isUploaded: true,
        isFailed: false,
        file_base_path: basePath
      }
    });
    setAddEditModal(true);
  }

  return (
    <>
      <table className="table table-sm cs-table cs-table-v2 w-100">
        <thead>
          <tr>
            <th style={{ width: "30px" }}>
              <Checkbox
                className="cs-select-v2"
                checked={documents && checked && documents.length > 0 && documents.length === checked.length}
                onChange={(e) => selectAll(e.target.checked)}
              />
            </th>
            <th>Document Name</th>
            <th>Comments</th>
            <th width="120px">Action</th>
          </tr>
        </thead>
        <tbody>
          {documents && documents.length ? documents.map(doc => (
            <tr key={doc.id}>
              <td><Checkbox onChange={() => onChange(doc)} checked={checked.findIndex(x => x.id === doc.id) !== -1} className="cs-select-v2" /></td>
              <td>{doc.document_name}</td>
              <td dangerouslySetInnerHTML={{ __html: doc.comments }}></td>
              <td>
                <div className="d-flex align-items-center" style={{ columnGap: "10px" }}>
                  <IconButton onClick={() => onEditDoc(doc)} className={JobStyles.MuiIconButton} style={{ color: "#9abe3a" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "14px", width: "14px", }}>
                      <path fill="currentColor" d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                    </svg>
                  </IconButton>
                  <IconButton style={{ color: "red" }} onClick={() => onDelete(doc)} className={JobStyles.MuiIconButton}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ height: "14px", width: "14px", }}>
                      <path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                    </svg>
                  </IconButton>
                  <IconButton style={{ color: "#9abe3a" }} onClick={(e) => onPreview(doc.shift_report_document_path)} className={JobStyles.MuiIconButton}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{ height: "14px", width: "14px" }}>
                      <path fill="currentColor" d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                    </svg>
                  </IconButton>

                  <IconButton style={{ color: "#362148" }} onClick={() => onDownload(doc.shift_report_document_path)} className={JobStyles.MuiIconButton}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: "14px", width: "14px" }}>
                      <path fill="currentColor" d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                    </svg>
                  </IconButton>
                </div>
              </td>
            </tr>
          )) : (
            <tr>
              <td colSpan={4} className="text-center" style={{ color: "red" }}>No Documents Available.</td>
            </tr>
          )}
        </tbody>
      </table>
      <button
        className={`${JobStyles.PrimaryButton} ${JobStyles.SmallPrimaryButton} mt-2`}
        type="button"
        onClick={() => onAddNewRecord()}
      >Add New Document</button>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={addEditModal}
        onCancel={() => setAddEditModal(false)}
        afterClose={() => { setSelectedDocument(null); refreshReports(); }}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        width={"600px"}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <AddEditJobReportDocument
          docuemnt={selectedDocument}
          initialValues={selectedDocument ? selectedDocument : { comments: "" }}
          onClose={() => setAddEditModal(false)}
          mode={selectedDocument && Object.keys(selectedDocument).length ? "Edit" : "Add"}
          jobId={jobId}
        />
      </Modal>
      <Modal
        className="cs-modal-v2"
        centered
        footer={null}
        visible={deleteModal}
        onCancel={() => setDeleteModal(false)}
        afterClose={() => { setSelectedDocument(null); refreshReports(); }}
        maskClosable={false}
        bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
        destroyOnClose={true}
        closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
      >
        <DeleteJobReportDocument
          selectedDocument={selectedDocument}
          onClose={() => setDeleteModal(false)}
        />
      </Modal>
    </>
  )
}