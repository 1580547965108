import React, { useState } from "react";
import Select from "react-select";
import colourStyles from "./FilterSelectStyles.js";
import { DatePicker } from "antd";
import styles from "./ReportsStyles.module.css";

const { RangePicker } = DatePicker;

export default function ReportFilters({ tasks, onFilter }) {

  const [selectedTasks, setSelectedTasks] = useState(null);
  const [range, setRange] = useState(null);

  const onClear = () => {
    setSelectedTasks(null);
    setRange(null);
    onFilter({});
  }

  const filter = () => {
    let obj = {
      ...(selectedTasks ? { task_id: selectedTasks.value } : {}),
      ...(range ? { range: range } : {})
    }
    onFilter(obj);
  }
  return (
    <div>
      <div className="row py-2">
        <div className="col-4">
          <Select
            placeholder="Tasks"
            options={tasks && tasks.length ? tasks.map(x => ({ label: `${x.task_name} - ${x.area_name}`, value: x.id })) : []}
            onChange={(v) => setSelectedTasks(v)}
            value={selectedTasks}
            hideSelectedOptions={false}
            aria-multiline={false}
            clearable
            styles={colourStyles}
          />
        </div>
        <div className="col-3">
          <RangePicker
            className="cs-filter-range-picker w-100"
            dropdownClassName="cs-datepicker-dropdown"
            allowClear format="DD-MM-YYYY"
            placeholder={["Shift Date From", "Shift Date To"]}
            value={range}
            onChange={v => setRange(v)}
          />
        </div>
        <div className="col-3">
          <button onClick={() => filter()} className={styles.PrimaryButton}>
            Filter
          </button>
          <button onClick={() => onClear()} className={`${styles.BtnResetTab} ml-1`}>
            Clear
          </button>
        </div>
      </div>
    </div>
  )
}